import React from 'react';
import '../../App.css';
import HeroSection from '../assetsEnglishNew/HeroSection';
import Footer from '../assetsEnglishNew/Footer';

function Home() {
  return (
    <>
      <HeroSection />
      <Footer />
    </>
  );
  }

export default Home;
