import React, { useState, useEffect } from 'react';
import '../../App.css';
import './HeroSection.css';
import Lottie from 'react-lottie';
import flamingo from '../../lottie/Flamingo.json'
import apple from './download.png'
import google from './downloadGoogle.png'
import bottom from './bottomNew.png'
import bottomAndroid from './bottomNewAndroid.png'

function HeroSection() {
  const defaultOptions = {
    loop: false,
    autoplay: false,
    animationData: flamingo,
    // initialDelay: 3000,
    // isStopped:true
    // rendererSettings: {
    //   preserveAspectRatio: "xMidYMid slice"
    // }
  };

  const [width, setWidth] = useState(window.innerWidth);

  const [autoplay, setAutoplay] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setAutoplay(true);
    }, 1350); // Delay the animation by 2 seconds
    return () => clearTimeout(timeout);
  }, []);


  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  console.log(`Screen width: ${width}`);

  return (
    <div className='hero-container'>
      {/* <img className='logo' src='/images/logo.svg'/> */}
      <div className='hero-container-2'>

        <Lottie
          options={{loop: false,animationData: flamingo,autoplay:autoplay}}
          height={width >= 700 ? 1280 / 2.2 : 1280 / 4.5}
          width={width >= 700 ? 1000 / 2.2 : 1280 / 4.5}
        />

      </div>
      <text className='promo'>deep questions. couple convos.</text>
      {width > 670 && (
    <div className='apple-container'>
      <img className='logo' src={apple} style={{marginRight: 20}}/>
      <img className='logo' src={google} />
    </div>
  )}
      {width < 670 && (
        <div className='bDiv'>
          <img className='bottom' src={/Android/i.test(navigator.userAgent) ? bottomAndroid : bottom} style={{ width: '370px' }} />
        </div>
      )}
      
    </div>
  );
}

export default HeroSection;
