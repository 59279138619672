import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div className='footer-container'>
      <section className='footer-subscription'>
      <div class='social-links'>
            <Link  class='social-links-design' onClick={()=>{window.open('https://flamingocards.notion.site/Misi-n-y-Cultura-3cb1f247bebc4cda991bd3113bbe7a56')      }}>{'nosotros'+'\u00A0'}</Link>
            <Link  class='social-links-design' onClick={()=>{window.open('mailto:team@flamingocards.co?subject=Customer%20Support&body=Hi%20Tipsy%20Flamingo%20Team,')      }}>contáctenos</Link>
            <Link  class='social-links-design' onClick={()=>{window.open('https://flamingocards.notion.site/T-rminos-de-Uso-d89239e7ca114624b7073ce2330fbb61')      }}>términos</Link>
            <Link  class='social-links-design' onClick={()=>{window.open('https://flamingocards.notion.site/Pol-tica-de-Privacidad-00ff9808bde7418d93607f4f99c9db2a')      }}>privacidad</Link>
            <Link  class='social-links-design' onClick={()=>{window.open('https://cartasflamingo.co/')}}>android</Link>
      </div>
      <div class='social-icons'>
            <Link
              class='social-icon-link tiktok'
              to={{ pathname: 'https://www.tiktok.com/@flamingo.cards' }}
              target='_blank'
              aria-label='Tiktok'
            >
              <i class='fab fa-tiktok fa-x' />
            </Link>
            <Link
              class='social-icon-link facebook'
              to={{ pathname: 'https://www.facebook.com/people/flamingocardsco/100090486347949/' }}
              target='_blank'
              aria-label='Facebook'
            >
              <i class='fab fa-facebook-f' />
            </Link>
            <Link
              class='social-icon-link instagram'
              to={{ pathname: 'https://www.instagram.com/flamingocards.co/?igshid=YmMyMTA2M2Y%3D' }}
              target='_blank'
              aria-label='Instagram'
            >
              <i class='fab fa-instagram' />
            </Link>
            <Link
              class='social-icon-link youtube'
              to={{ pathname: 'https://www.youtube.com/@flamingocards' }}
              target='_blank'
              aria-label='Youtube'
            >
              <i class='fab fa-youtube' />
            </Link>
            <Link
              class='social-icon-link twitter'
              to={{ pathname: 'https://twitter.com/flamingocard?s=11&t=GSK_7CaTApy0L0fIHS0L8Q' }}
              target='_blank'
              aria-label='Twitter'
            >
              <i class='fab fa-twitter' />
            </Link>
            <Link
              class='social-icon-link twitter'
              to={{ pathname: 'https://flamingoandroid.app/' }}
              target='_blank'
              aria-label='Twitter'
            >
              <i class='fab fa-google-play' />
            </Link>
          </div>
        <div class='social-media-wrap'>
          <small class='website-rights'>© creando recuerdos una tarjeta a la vez 2023.</small>
        </div>
      </section>
    </div>
  );
}

export default Footer;
